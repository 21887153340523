import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, fetchLanguages } from 'wwtc-sdk';
//import * as AWS from 'aws-sdk/global';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import userPool from '../constants/cognito';
import { getRandomID, getMd5 } from './utils';
import './login.css';
import './common/button.css';
import { Cog, Times } from './svg/icons';

function Login() {
    const history = useHistory();
    const [form, setForm] = useState({
        language: null,
        displayName: null,
        gender: null,
    });
    const [info, setInfo] = useState({
        loading: false,
        error: null,
        languages: [],
    });
    const [errors, setErrors] = useState([]);
    const [modalSettings, setModalSettings] = useState(false);
    const [roomType, setRoomType] = useState('persistent');

    useEffect(() => {
        fetchLanguages().then((languages) => {
            setInfo({
                ...info,
                languages,
            });
        });
    }, []);

    const handleLanguage = (language) => {
        setForm({ ...form, language });
    };

    const handleGender = (gender) => {
        setForm({ ...form, gender });
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (validateLogin()) {
            const { language, displayName, gender } = form;
            const roomName = roomType === 'persistent' ? getMd5(displayName) : getRandomID(6);
            setInfo({
                ...info,
                loading: false,
            });
            history.push(`/app`, {
                userName: displayName,
                room: roomName,
                language: language.value,
                gender: gender,
                userInvite: false,
                roomType,
            });
        } else {
            setInfo({
                ...info,
                error: 'Please fill inputs to continue',
            });
        }
    };

    const validateLogin = () => {
        let valid = true;
        let errors = [];
        Object.keys(form).forEach((item) => {
            if (!form[item] || form[item] === '') {
                valid = false;
                errors.push(item);
            }
        });
        setErrors(errors);
        return valid;
    };

    const errorDisplayName = errors.find((item) => item === 'displayName');
    const errorGender = errors.find((item) => item === 'gender');

    return (
        <div className="wrapper-login">
            <div className="wrapper-content">
                <div className="wrapper-login-modal">
                    <div className="wrapper-logo">
                        <img
                            src="https://staticfiles.worldwidetechconnections.com/clients/31/logo-login/89eeb256-be7a-441c-aaa9-801d5a4b64b5.png"
                            alt="WWTC"
                        />
                    </div>
                    <form className="form-modal" onSubmit={handleLogin}>
                        <div className="title-welcome">
                            <h2>Now Translate</h2>
                            <div style={{ position: 'absolute', right: 0, top: 0 }}>
                                <button
                                    className="a-element"
                                    type="button"
                                    onClick={() => setModalSettings(true)}
                                    style={{ color: '#ccc' }}
                                >
                                    <Cog />
                                </button>
                            </div>
                        </div>
                        <div className="wrapper-login-form">
                            <div className="login-inputs">
                                <Select
                                    handleChange={handleLanguage}
                                    data={info.languages}
                                    value={form.language || ''}
                                    placeholder="Select language"
                                    showLanguageServices={true}
                                />
                            </div>
                            <div className="login-inputs">
                                <input
                                    className={`Input ${errorDisplayName ? 'Input-error' : ''}`}
                                    type="text"
                                    placeholder="Name"
                                    value={form.displayName || ''}
                                    onChange={(e) =>
                                        setForm({ ...form, displayName: e.target.value })
                                    }
                                />
                            </div>
                            <div style={{ height: 16 }}>
                                <p
                                    style={{
                                        margin: 8,
                                        textAlign: 'center',
                                        fontSize: 12,
                                        color: '#ccc',
                                    }}
                                >
                                    Select your language configuration
                                </p>
                            </div>
                            <div
                                className="login-inputs"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                }}
                            >
                                <button
                                    type="button"
                                    className={`Button Button-small Button-gender ${
                                        form.gender === 'male' ? 'Button-gender-active' : ''
                                    }`}
                                    onClick={() => handleGender('male')}
                                >
                                    <span>Masculine</span>
                                </button>
                                <button
                                    type="button"
                                    className={`Button Button-small Button-gender ${
                                        form.gender === 'female' ? 'Button-gender-active' : ''
                                    }`}
                                    onClick={() => handleGender('female')}
                                >
                                    <span>Feminine</span>
                                </button>
                            </div>
                            <div style={{ height: 16 }}>
                                {errorGender ? (
                                    <p
                                        style={{
                                            margin: 0,
                                            textAlign: 'center',
                                            fontSize: 14,
                                            color: 'lightcoral',
                                        }}
                                    >
                                        Select gender to continue
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="wrapper-button-actions">
                            <button
                                onClick={handleLogin}
                                type="submit"
                                className="Button Button-primary Button-block"
                            >
                                {info.loading ? (
                                    <img
                                        src="https://sdkresources.s3.amazonaws.com/assets/oval.svg"
                                        style={{ width: 14, height: 14 }}
                                        alt="loading"
                                    />
                                ) : (
                                    'connect'
                                )}
                            </button>
                        </div>
                        <div className="error-message">
                            {info.error ? <p>{info.error}</p> : null}
                        </div>
                    </form>
                </div>
                {modalSettings ? (
                    <ModalSettings
                        closeModal={() => setModalSettings(false)}
                        roomType={roomType}
                        setRoomType={setRoomType}
                    />
                ) : null}
            </div>
        </div>
    );
}

const ModalSettings = (props) => {
    const { closeModal, roomType, setRoomType } = props;

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 200, width: 350 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Settings</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={closeModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div
                    className="body-container"
                    style={{ ...stylesWrapper.bodyModal, textAlign: 'center' }}
                >
                    <p>Room type</p>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <input
                                type="radio"
                                id="persistent"
                                name="roomType"
                                value="persistent"
                                checked={roomType === 'persistent'}
                                onChange={(e) => setRoomType(e.target.value)}
                            />
                            <label htmlFor="persistent">Persistent</label>
                        </div>

                        <div>
                            <input
                                type="radio"
                                id="random"
                                name="roomType"
                                value="random"
                                checked={roomType === 'random'}
                                onChange={(e) => setRoomType(e.target.value)}
                            />
                            <label htmlFor="random">Random</label>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 48,
                        }}
                    >
                        <button
                            type="button"
                            className="Button Button-small Button-primary"
                            onClick={closeModal}
                        >
                            save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const stylesWrapper = {
    containerModal: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 50px)',
        width: '100vw',
        background: 'rgba(1,1,1,0.3)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 2000,
    },
    bodyModal: {
        marginTop: 15,
        height: 'calc(100% - 30px)',
        overflow: 'auto',
    },
    wrapperModal: {
        padding: '10px 5px',
        backgroundColor: 'white',
        borderRadius: 5,
        height: '350px',
        top: 'calc(50% - 250px)',
        width: '250px',
        position: 'absolute',
        WebkitBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        MozBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        boxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
    },
};

export default Login;
