import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, fetchLanguages, pubnub } from 'wwtc-sdk';
import './login.css';
import './common/button.css';

// Get value from query URL
export const getValueFromQuery = (value) => {
    const plainUrl = window.location.href;
    const objUrl = new URL(plainUrl);

    return objUrl.searchParams.get(value);
};

function Invitation() {
    const history = useHistory();
    const params = history.location.search;

    const room = getValueFromQuery('room');
    // const hostId = getValueFromQuery(params, 'hostId');

    const [form, setForm] = useState({
        language: null,
        displayName: null,
        gender: null,
    });
    const [info, setInfo] = useState({
        loading: false,
        error: null,
        languages: [],
    });
    const [errors, setErrors] = useState([]);
    const [isModeratorPresent, setIsModeratorPresent] = useState(false);

    useEffect(() => {
        pubnub.addListener({
            presence: function (event) {
                var action = event.action;
                var state = event.state;

                if (action === 'state-change' && state.role === 'moderator') {
                    setIsModeratorPresent(true);
                }

                if (action === 'leave' && state.role === 'moderator') {
                    setIsModeratorPresent(false);
                }
            },
        });

        pubnub.subscribe({
            channels: [room],
            withPresence: true,
        });

        pubnub
            .hereNow({
                channels: [room],
                includeState: true,
            })
            .then(async (res) => {
                // If moderator is present in the room
                // allow to access to invited user
                const participantsInChannel = res.channels[room].occupants;
                const isModerator = participantsInChannel.find(
                    (part) => part.state.role === 'moderator',
                );
                if (isModerator !== undefined) {
                    setIsModeratorPresent(true);
                }

                fetchLanguages().then((languages) => {
                    setInfo({
                        ...info,
                        languages,
                    });
                });
            });
    }, []);

    const handleLanguage = (language) => {
        setForm({ ...form, language });
    };

    const handleGender = (gender) => {
        setForm({ ...form, gender });
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (validateLogin()) {
            const { language, displayName, gender } = form;
            setInfo({
                ...info,
                loading: true,
            });
            history.push(`/app`, {
                userName: displayName,
                room: room,
                language: language.value,
                gender: gender,
                userInvite: true,
            });
        } else {
            setInfo({
                ...info,
                error: 'Please fill inputs to continue',
            });
        }
    };

    const validateLogin = () => {
        let valid = true;
        let errors = [];
        Object.keys(form).forEach((item) => {
            if (!form[item] || form[item] === '') {
                valid = false;
                errors.push(item);
            }
        });
        setErrors(errors);
        return valid;
    };

    const errorDisplayName = errors.find((item) => item === 'displayName');
    const errorGender = errors.find((item) => item === 'gender');

    return (
        <div className="wrapper-login">
            <div className="wrapper-content">
                <div className="wrapper-login-modal">
                    <div className="wrapper-logo">
                        <img
                            src="https://staticfiles.worldwidetechconnections.com/clients/31/logo-login/89eeb256-be7a-441c-aaa9-801d5a4b64b5.png"
                            alt="WWTC"
                        />
                    </div>
                    <form className="form-modal" onSubmit={handleLogin}>
                        <div className="title-welcome">
                            <h2>Login</h2>
                        </div>
                        <div className="wrapper-login-form">
                            <div className="login-inputs">
                                <Select
                                    placeholder="Select language"
                                    handleChange={handleLanguage}
                                    data={info.languages}
                                    value={form.language || ''}
                                />
                            </div>
                            <div className="login-inputs">
                                <input
                                    className={`Input ${errorDisplayName ? 'Input-error' : ''}`}
                                    type="text"
                                    placeholder="Name"
                                    value={form.displayName || ''}
                                    onChange={(e) =>
                                        setForm({ ...form, displayName: e.target.value })
                                    }
                                />
                            </div>
                            <div
                                className="login-inputs"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center',
                                }}
                            >
                                <button
                                    type="button"
                                    className={`Button Button-small Button-gender ${
                                        form.gender === 'male' ? 'Button-gender-active' : ''
                                    }`}
                                    onClick={() => handleGender('male')}
                                >
                                    <span>Masculine</span>
                                </button>
                                <button
                                    type="button"
                                    className={`Button Button-small Button-gender ${
                                        form.gender === 'female' ? 'Button-gender-active' : ''
                                    }`}
                                    onClick={() => handleGender('female')}
                                >
                                    <span>Feminine</span>
                                </button>
                            </div>
                            <div style={{ height: 16 }}>
                                {errorGender ? (
                                    <p
                                        style={{
                                            margin: 0,
                                            textAlign: 'center',
                                            fontSize: 14,
                                            color: 'lightcoral',
                                        }}
                                    >
                                        Select gender to continue
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="wrapper-button-actions">
                            <button
                                onClick={handleLogin}
                                type="submit"
                                className={`Button ${
                                    isModeratorPresent ? 'Button-primary' : 'Button-gray'
                                } Button-block`}
                                disabled={!isModeratorPresent}
                            >
                                {info.loading ? (
                                    <img
                                        src="https://sdkresources.s3.amazonaws.com/assets/oval.svg"
                                        style={{ width: 14, height: 14 }}
                                        alt="loading"
                                    />
                                ) : (
                                    'connect'
                                )}
                            </button>
                        </div>
                        <div className="error-message">
                            {info.error ? <p>{info.error}</p> : null}
                        </div>
                        {!isModeratorPresent && (
                            <div className="error-message">
                                <p style={{ color: 'red' }}>
                                    Waiting for Moderator to Begin Meeting
                                </p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Invitation;
